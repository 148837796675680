import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/shared/Layout";
import { Box, Container } from "@chakra-ui/react";
import SectionHeading from "../components/shared/SectionHeading";
import styled from "@emotion/styled";
import { theme } from "../styles/theme";
import Head from "../components/shared/Head";

const Privacy = ({ data, location }) => {
  const allData = data.allContentfulPrivacy.edges[0].node;

  return (
    <Layout location={location}>
      <Head data={allData.seo} />
      <Container id="invest" maxW="full" centerContent py={[28, 56]}>
        <Box maxW="6xl" w="full">
          <SectionHeading textAlign={["left"]} pb={[2, 3, 12]}>
            {allData.title}
          </SectionHeading>
          <TextContainer
            dangerouslySetInnerHTML={{
              __html: allData.text.childrenMarkdownRemark[0].html,
            }}
          />
        </Box>
      </Container>
    </Layout>
  );
};

const TextContainer = styled.div`
  h2 {
    color: ${theme.colors.brand[100]};
    font-weight: 600;
    font-size: ${theme.fontSizes.lg};

    @media (min-width: 30rem) {
      font-size: ${theme.fontSizes.xl};
    }

    @media (min-width: 48rem) {
      font-size: ${theme.fontSizes["2xl"]};
    }
  }

  & p {
    padding-bottom: 1em;
    font-weight: 300;
    color: ${theme.colors.brand[100]};
    font-size: ${theme.fontSizes.sm};
    text-align: left;
    white-space: pre-line;

    @media (min-width: 30rem) {
      font-size: ${theme.fontSizes.md};
    }
  }
`;

export const query = graphql`
  {
    allContentfulPrivacy {
      edges {
        node {
          title
          text {
            childrenMarkdownRemark {
              html
            }
          }
          seo {
            pageTitle
            metaImage {
              file {
                url
              }
            }
            metaDescription {
              metaDescription
            }
          }
        }
      }
    }
  }
`;

export default Privacy;
